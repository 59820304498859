@tailwind base;
@tailwind components;
@tailwind utilities;

.animate-spin-slow {
    animation: spin 10s linear infinite;
  }
  
  @keyframes spin {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
  }

  .square-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    background-color: transparent;
    border: 0px solid black;
  }